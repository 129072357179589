<template>
    <layout-heading>
        <portal to="title">
            <img src="../../assets/images/icon-QR.svg" alt="">
            <span>{{ title }}</span>
        </portal>

        <div v-if="error">
            <p class="error">There was an error verifying your link:</p>
            <p>{{error}}</p>
        </div>

        <div v-if="verify.password">
            <p>
                {{verify.message}}
            </p>

            <div v-if="passwordSet" class="password-set">
                <p>
                    <strong>Password set!</strong>
                </p>
                <p>
                    <router-link to="/app/login">Click Here</router-link>
                    to Sign In.
                </p>
            </div>

            <FormulateForm v-else @submit="submitPassword">
                <FormulateInput
                    name="password"
                    type="password"
                    label="Password"
                    validation="required"
                    help="Enter a new password."
                    class="width-5"
                    v-model="password"
                />
                <FormulateInput
                    type="submit"
                    label="Submit"
                />
            </FormulateForm>
        </div>

        <div v-else-if="verify.email">
            <p>
                <strong>Your email address is now verified</strong>. You can close this tab, or
                <router-link to="/app/wallet">go to your wallet</router-link>
                .
            </p>
        </div>

        <div v-else-if="used">
            <p>
                This verification link has already been used. If you believe you've received this message
                in error, contact us at <a href="mailto:blockwell@blockwell.ai">blockwell@blockwell.ai</a>.
            </p>
        </div>

        <div v-else-if="expired">
            <p>
                This verification link has expired. We've sent you a new link to the email address associated with your
                account, please use that instead.
            </p>
            <p>
                If you believe you've received this message in error, contact us at <a
                href="mailto:blockwell@blockwell.ai">blockwell@blockwell.ai</a>.
            </p>
        </div>

        <div v-else>
            <progress class="pure-material-progress-linear"></progress>
        </div>
    </layout-heading>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        data() {
            return {
                title: "Verification",
                used: false,
                expired: false,
                verify: {},
                error: null,
                password: "",
                passwordSet: false
            }
        },
        computed: {
            ...mapGetters('user', ['api']),
        },
        created() {
            this.verifyToken(this.$route.params.token)
                .catch(err => {
                    this.error = err.message;
                })
        },
        methods: {
            async verifyToken(token) {
                try {
                    this.verify = await this.api.verify(token);
                } catch (err) {
                    if (err.code === "token_used") {
                        this.used = true;
                    } else if (err.code === "token_expired") {
                        this.expired = true;
                    } else {
                        this.error = err.message;
                    }
                }
            },
            async submitPassword() {
                this.api.setPassword(this.$route.params.token, this.password)
                    .then(res => {
                        this.passwordSet = true;
                    })
                    .catch(err => {
                        this.error = err.message;
                    })
            }
        }
    }
</script>
