<template>
    <layout-heading>
        <portal to="title">
            <img src="../../assets/images/icon-QR.svg" alt="">
            <span>Sign In</span>
        </portal>

        <div class="row align-items-start no-gutters">
            <div class="col-md-7 content-column">
                <p class="signup-notice">
                    <strong>If you don't have an account</strong>, just type in an email address and a
                    password and one will be created for you.
                </p>

                <LoginForm />
                <router-link to="/app/forgot">Forgot Password?</router-link>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-4 sidebar">
                <p>
                    You can also use our Android app: <a href="https://cdn.blockwell.ai/public/dist/apk/blockwell--v1.11.1-release.apk">Download
                    APK</a>.
                </p>
                <p>
                    <a href="https://cdn.blockwell.ai/public/dist/apk/blockwell--v1.11.1-release.apk"> <img
                            src="../../assets/images/download-android.png"
                            width="200"> </a>
                </p>
            </div>
        </div>
    </layout-heading>
</template>

<script>
import LoginForm from "@/components/LoginForm";

export default {
    components: {LoginForm}
}

</script>

<style scoped lang="scss">
.signup-notice {
    font-size: 1.4em;
    margin-bottom: 30px;
}
</style>
