<template>
    <layout-heading>
        <portal to="title">
            <img src="../../assets/images/icon-QR.svg" alt="">
            <span>Forgot Password</span>
        </portal>

        <div v-if="submitted">
            <p>
                An email has been sent to your email address with instructions for resetting your password. If you
                don't see an email within 10 minutes, make sure you entered the correct email address.
            </p>
        </div>
        <div v-else class="row align-items-start no-gutters">
            <div class="col-md-7 content-column">
                <p>
                    Enter your email address to reset your password.
                </p>
                <FormulateForm v-model="form" @submit="submit">
                    <FormulateInput
                        name="email"
                        type="email"
                        label="Email"
                        validation="email"
                        help="Enter your account email address."
                        class="width-8"
                    />
                    <FormulateInput
                        type="submit"
                        label="Reset Password"
                    />
                </FormulateForm>
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-4 sidebar">
                <p>
                    Did you know that we have an Android app? <a href="/public/dist/blockwell-qr.apk">Download
                    APK</a>.
                </p>
                <p>
                    <a href="/public/dist/blockwell-qr.apk"> <img src="../../assets/images/download-android.png"
                                                                  width="200"> </a>
                </p>
            </div>
        </div>
    </layout-heading>
</template>

<script>
    import * as util from "@/assets/lib/util";
    import {mapGetters} from "vuex";

    export default {
        components: {},
        data() {
            return {
                form: {
                    email: ""
                },
                submitted: false
            };
        },
        computed: {
            ...mapGetters('user', ['api']),
        },
        methods: {
            submit() {
                this.api.forgotPassword(this.form.email)
                    .then(res => {
                        this.submitted = true;
                    })
                    .catch(console.error);
            }
        }
    }
</script>
